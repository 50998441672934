import React from 'react'

const SubmitReview = () => {
  return (
    <div className='w-full py-[3rem] px-4 bg-slate-100 '>
      <p>
        Submit a Review service enables you to share a link with customers that have had experience with your product and services. Simply provide the Name and email address of the customer and our system generates and send an email to the customer to provide you with review/feedback or testimony on their experience with your product/service. All feedbacks and experiences or testimonies are private and are only available for your use in your PaySprint Merchant Account. You can share customer Review or Testimony by clicking on 'Approve for sharing' icon right in front of each feedback/review or testimony.
      </p>

      
<form>
  <div className="mb-6 my-6">
    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
    <input type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Customer Name" required=""/>
  </div>
  <div className="mb-6">
    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
    <input type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='Customer Email' required=""/>
  </div>

  <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
</form>

    </div>
  )
}

export default SubmitReview

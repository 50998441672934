import React,{useEffect,useState} from 'react'
import axios from 'axios';
import { photography, } from "../assets";
import baseurl from '../constants/baseurl';
import headers from '../constants/headers';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Market  (){

  const [allProduct, setAllProduct] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    getAllProducts();
  }, []);

  const getAllProducts = async () => {
    try {

      const config = {
        method: 'GET',
        url: `${baseurl}/getallproducts`,
        headers
      }

      const response = await axios(config);
      console.log(response.data.data)
      setAllProduct(response.data.data);
      setStatus(true);
      
    } catch (error) {
      console.log(error);
    }
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }

  const isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
}

  return (
    
    <div>
      
      <Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  // td 
  autoPlay={isMobile !== "desktop" ? true : false}
  autoPlaySpeed={500}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={3000}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={isMobile}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
        {
          status ? (allProduct ?.length > 0 ?
          allProduct.map((item,key) =>{
   return    <div className='w-full bg-white py-16 px-4' key={key}>
   <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
     <img className=' mx-auto my-4 w-[300px] h-[300px]' src={item.data.image.length > 0 ? item.data.image : photography} alt='/' />
     <div className='flex flex-col justify-center mx-4'>
      <h3 className='md:text-3xl  sm:text-2xl text-1xl font-bold py-2'>{item.data.productName}</h3>
       <p>
       {item.data.description.replace(/(<([^>]+)>)/gi, "")}
       </p>
       <a className="order-service-btn" href={`https://paysprint.ca/shop/${item.merchant.businessname}`}>
       Go to E-Store
                  </a>
     </div>
   </div>
 </div>
   
   
   
  
     })
     :
     <div className="px-5 pb-5 ">
     <h5 className="text-xl font-medium tracking-tight text-gray-900 dark:text-white">
       No Product available at the moment
     </h5>
     
   </div>) : <p>Loading...</p> 
  }
  
  
</Carousel>
    </div>
  )
}

export default Market

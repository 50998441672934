import React from 'react'
import {
  Cardss,Navbar
} from "../components";

function Community() {
  return (
    <>
    <Navbar/>
    <div className='container'>
      <Cardss/>
    </div>
    </>
  )
}

export default Community;
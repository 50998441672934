import React from 'react'

const Video = () => {
  return (
<div >
{/* <img src="https://res.cloudinary.com/paysprint/image/upload/v1651130089/assets/paysprint_jpeg_black_bk_ft8qly_frobtx.jpg" alt="" class="w-100 rounded-8" /> */}
<iframe width="450" height="587" src="https://www.youtube.com/embed/ptsmEYFJMx4" title="paysprint" allowFullScreen></iframe>
</div>
  )
}

export default Video
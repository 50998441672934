import React from 'react'
import {
  Navbar
} from "../components";

function Supports
() {
  return (
    <>
    <Navbar/>
    <div>
      Supports
      
    </div>
    </>
  )
}

export default Supports

import axios from "axios";
import React,{useState} from "react";
import { useParams, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import { FaHeart } from 'react-icons/fa';
import baseurl from "../../constants/baseurl";

const Comment = () => {
  const [formData, setFormData] = useState();
  const [message,setMessage] =useState();
  const [error,setError] =useState();
  const state = useLocation();

  // eslint-disable-next-line
  const [merchant, setMerchant] = useState(state.state.merchant);
  const [product, setProduct] = useState(state.state.product);
  const [merchantId, setMerchantId] = useState(state.state.merchantId);
 
  const {id} = useParams();
    
  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      comment: formData?.comment,
      email: formData?.email,
      name_sender: formData?.name_sender,
      like:formData?.like,
      product_id:id,
      merchant_id:merchantId
    };
    try {
      const config={
        method:'POST',
        url:`${baseurl}/comment`,
        data
      };
      const response = await axios(config);
      
      setMessage(response.data.message)
    } 
    catch (error) {
      if (error.response) {
        setError(error.response.data.message)
      }
      else{
        setError(error.message)
      }
      
    }
  }

  


  // const handleSubmit (){

  // }
  return (
    
    <div>
      <Navbar />
      
      <div className="w-full py-[3rem] px-4 bg-slate-100">
        <h4 className="text-3xl font-thin  pb-8 text-black text-center" id="comment">
          Comment on {product} by {merchant}
        </h4>
        <div className="mx-4">
          <div className="message">
          <p style={{color:"blue"}}>{message}</p>
          <p style={{color: "red"}}>{error}</p>
          </div>
          <form>
          <div classNames="mb-6 mt-5">
              <label
                for="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Your FullName
              </label>
              <input
                type="text"
                
                onChange={handleFormDataChange}
                id="text"
                name="name_sender"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Your Full Name"
                required=""
              />
            </div>
            
            <div classNames="mb-6 mt-5">
              <label
                for="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Your email
              </label>
              <input
                type="email"
                name="email"
                onChange={handleFormDataChange}
                id="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Your Email"
                required=""
              />
            </div>
            <div className="mb-6 mt-5">
              <label
                for="comment"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Type your comment
              </label>
              <textarea style={{ height:"100px", width: "100%" }} onChange={handleFormDataChange}
                id="text"
                name="comment" required=""  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
             
            </div>
            <div className="flex items-center mt-3 mb-3">
            <input id="default-checkbox" type="checkbox" value="1" onChange={handleFormDataChange} name="like"className="w-7 h-9 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
    <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"><FaHeart/>Check the box to like the product.</label>

</div>

            <button
              type="submit"
              onClick={handleSubmit}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Comment;

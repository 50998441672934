import React from 'react'
import Navbar from './Navbar'

const Claimnumber = () => {
  return (
    <div>
        <Navbar/>
        <div className='w-full py-[4rem] px-4 bg-slate- align-center'>
        <form className="w-auto ">
  <div class="flex items-center border-b border-[#e8aa07] py-2">
    <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="tel"  />
    <button class="flex-shrink-0 bg-[#e8aa07] hover:bg-[#f8f7bc] border-bg-[#e8aa07] hover:border-bg-[#e8aa07] text-sm border-4 text-black py-1 px-2 rounded" type="button">
      Submit
    </button>
    <button className="flex-shrink-0 border-transparent border-4 text-black hover:text-teal-800 text-sm py-1 px-2 rounded" type="button">
      Cancel
    </button>
  </div>
        </form>
        </div>
    </div>
  )
}

export default Claimnumber
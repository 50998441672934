import React from 'react'
import {SubmitReview,Navbar} from '../find_a_product'

const Submit = () => {
  return (
    <>
   <Navbar/>
   <div className='container'>
    <SubmitReview/>
   </div>
   </>
  )
}

export default Submit

import React,{ useState, useEffect } from 'react'
import axios from "axios";
import baseurl from "../../constants/baseurl";
import Navbar from './Navbar';
import {useLocation } from "react-router-dom";


const Viewcomment = () => {
  const state = useLocation();
  const [viewComments, setViewComments] = useState();
  const [status, setStatus] = useState(false);

   
   const [merchant, setMerchant] = useState(state.state.merchant);
   const [product, setProduct] = useState(state.state.product);
   const [id, setId] = useState(state.state.id);
   const [image, setImage] = useState(state.state.image);

   
   useEffect(()=>{
    getComment();
  },[])

  const getComment = async () =>{
    try {

      const config={
        method:'GET',
        url:`${baseurl}/viewcomments/${id}`
      }
       const response = await axios(config);
      console.log(response.data.data);

      setViewComments(response.data.data);

      
      setStatus(true)


    } catch (error) {
      console.log(error)
    }
  }


    
 
  return (
    <div>
      <Navbar/>
      <div className='w-full py-[3rem] px-4 bg-slate-100 '>
      <h4 className="text-3xl font-thin  pb-8 text-black text-center">
          Comments on {product} by {merchant}.
        </h4>
        <div>
          
          {
            status ? viewComments.length > 0 ? viewComments.map((item, key) => {
             return <div class="comments-container" key={key} >
 <ul id="comments-list" class="comments-list">
   <li>
     <div class="comment-main-level">
       
       <div class="comment-avatar"><img src="https://res.cloudinary.com/dysgwap7p/image/upload/v1665052608/Logikeep/avatar-image_oqym5e.png" alt=""/></div>
      
       <div class="comment-box">
         <div class="comment-head">
           <h6 class="comment-name by-author">{item.data.name_of_sender}</h6>
           <span>{new Date(item.data.created_at).toLocaleString()}</span>
           
         </div>
         <div class="comment-content">
        {item.data.comment}
         </div>
       </div>
     </div>
     
     {
             Object.keys(item.reply).length > 0 ? <ul class="comments-list reply-list">
            <li>
               <div class="comment-avatar"><img src={image}alt="image"/></div>
               <div class="comment-box">
                 <div class="comment-head">
                    <h6 class="comment-name">{product} </h6>
                  <span>{new Date(item.reply.created_at).toLocaleString()}</span>
                  
                  </div>
                  <div class="comment-content">
                  {item.reply.merchant_reply}
                  </div>
                </div>
               </li>
             </ul> : null
            }
     </li>
 </ul>
</div>
            }) : <div className="px-5 pb-5">
            <h5 className="text-xl font-medium tracking-tight">
              No available store at the moment
            </h5>
            
          </div> : 'Loading...'
          }

        </div>
      </div>
      </div>
  )
}

export default Viewcomment
import React from 'react'
import { Navbar,Hero } from './find_a_product'


function Products() {
  return (
    <>
    <Navbar/>
    <div className="container">
     <Hero/> 
    
    </div>
    </>
  )
}

export default Products
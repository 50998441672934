import React from 'react'

const RequestReview = () => {
  return (
    <div className='w-full py-[3rem] px-4 bg-slate-100 '>
      <p>
        Request for Free Review is a PaySprint Merchant Service that generates invite to PaySprint Members to buy your product or service either on your website or physical store at a discounted price using a promotional code.
        Request for Review email is generated to all customers that provided their Name and Email address while using the promotional code to make purchases from you. All feedbacks and experiences or testimonies are private and are only available for your use in your PaySprint Merchant Account. You can share customer Review or testimony by clicking 'Approve for sharing' icon in front of each feedback/review or testimony.

      </p>
    </div>
  )
}

export default RequestReview

import React from 'react'

const footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-5 gap-4 text-black  justify-between footer'>
    <div>
        <h1 className='w-full text3xl font-bold'>About Our Company</h1>
        <p className='py-4 '>PaySprint is the fastest and most affordable way to send and receive money pay invoice and getting paid at anytime!</p>
    </div>
    <div className='px-5'>
    <h1 className='w-full text3xl font-bold'>Services</h1>
    <ul>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/mywallet">Money Transfer</a></li>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/Invoice">Pay Invoice</a></li>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/mywallet">Wallet</a></li>

    </ul>
    </div>
    <div className='px-5'>
    <h1 className='w-full text3xl font-bold'>Quick Link</h1>
    <ul>
   
        <li className='py-2 text-sm'><a href="https://paysprint.ca/about">About Us</a></li>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/blog">Blog</a></li>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/contact">Contact Us</a></li>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/developers/community">Community Forum</a></li>
        <li className='py-2 text-sm'><a href="https://investor.paysprint.ca">Investor Relation</a></li>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/login">Open a Personal Account</a></li>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/AdminLogin">Open a Business Account</a></li>

    </ul>
    </div>
    <div className='px-5'>
  
    <ul>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/terms-of-service">Terms of Use</a></li>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/privacy-policy">Privacy Policy</a></li>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/pricing">Pricing</a></li>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/login">Login</a></li>
        <li className='py-2 text-sm'><a href="https://paysprint.ca/register">Sign Up for Free</a></li>

    </ul>
    </div>
    <div className=''>
    <h1 className='w-full text3xl font-bold'>Contact Us</h1>
    <p className='py-2'>Info@paysprint.ca</p>
    <p className='py-2'>PaySprint International 10,George St. North,Brampton ON.L6X1R2. Canada</p>
    
    </div>
    {/* <div class="copyright-area border-top pt-9 pb-8">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <p class="copyright-text gr-text-11 mb-6 mb-lg-0 gr-text-color text-center text-lg-left">
                            © 2019 - 2022 Copyright, All Right Reserved
                        </p>
                    </div>
                    <div class="col-lg-6 text-center text-lg-right">
                        <ul className="social-icons list-unstyled mb-0 mr-n9">
                            <li className="gr-text-7"><a href="https://twitter.com/EXBC2"
                                    class="gr-text-color-opacity mr-9"><i class="icon icon-logo-twitter"></i></a></li>
                            <li className="gr-text-7"><a
                                    href="https://www.facebook.com/EXPRESSCANADA2014/?modal=admin_todo_tour"
                                    class="gr-text-color-opacity mr-9"><i class="icon icon-logo-facebook"></i></a></li>
                            <li className="gr-text-7"><a
                                    href="https://www.linkedin.com/company/exbc-canada/?viewAsMember=true"
                                    class="gr-text-color-opacity mr-9"><i class="icon icon-instant-camera-2"></i></a>
                            </li>
                            <li className="gr-text-7"><a
                                    href="https://www.linkedin.com/company/exbc-canada/?viewAsMember=true"
                                    class="gr-text-color-opacity mr-9"><i class="icon icon-logo-linkedin"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}

    </div>
  )
}

export default footer

import React from 'react'
import {
  Card,Navbar
} from "../components";


function Refer() {
  return (
    <>
    <Navbar/>
    <div className='container'>
      <Card/>
    </div>
    </>
  )
}

export default Refer;
import React from 'react'
import {SearchBusiness,Navbar} from '../find_a_product'
import ClaimBusiness from './ClaimBusiness'
const Claim = () => {
  return (
    <div>
    <>
   <Navbar/>
   <div className='container'>
    <h1 className=' bg-slate-100 text-4xl text-center py-6 pt- font-medium'>Search Your Business and Get Listed</h1>
    <ClaimBusiness/>
   </div>
   </>
    </div>
  )
}

export default Claim
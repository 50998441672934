import React from 'react'

function Hero() {
  return (
    <div className="w-full py-[3rem] px-4 bg-slate-100">
    <h4 className="text-3xl font-medium  pb-8 text-black text-center">
      PaySprint E-Connect
  </h4>
  <p className='text-1xl font-normal py-2'>
   PaySprint connects local businesses to people nearby through the FREE directory listing and business information sharing services. This Value-Added Services provide merchants with more visibility and able to drive more traffic to their business.
  </p>
 
</div>
  )
}

export default Hero

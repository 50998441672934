import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import baseurl from "../../constants/baseurl";
import { Link } from "react-router-dom";
import { ps } from "../../assets/index";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import { Typography } from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { Link } from 'react-router-dom';


function ClaimBusiness() {
  const [searchClaims, setSearchClaims] = useState();
  const [searchBusiness, setSearchBusiness] = useState([]);
  const [formData, setFormData] = useState();
  const [message,setMessage] =useState();
  const [error,setError] =useState();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState(false);
  const [loading,setLoading]= useState(false)

  let loadingRef = useRef(null)
  
 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    searchClaim();

  }, []);

  






  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: formData?.email,   
    };
    try {
      const config={
        method:'POST',
        url:`${baseurl}/claimmarketbusiness`,
        data
      };
      const response = await axios(config);
      
      setMessage(response.data.message)
    } 
    catch (error) {

      // console.log(error);

      if (error.response) {
        setError(error.response.data.message)
      }
      else{
        setError(error.message)
      }
     
    }
  }

  

  const searchClaim = async () => {
    try {
      const config = {
        method: "GET",
        url: `${baseurl}/getallunverifiedmerchants`,
      };

      const response = await axios(config);
      // console.log(response);

      setSearchClaims(response.data.data);
      setStatus(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="w-full py-[2rem] px-4 bg-slate-100">
      <form className="flex items-center">
        <label for="simple-search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <input
            type="text"
            id="search"
            onChange={(event)=>{
              setSearchBusiness(event.target.value);
            }}
            value={searchBusiness}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
            placeholder="Search Your Business"
            required=""
          />
        </div>
        <button
          type="submit"
          className="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 "
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
          <span className="sr-only">Search</span>
        </button>
      </form>
     
                <div
                  className="max-w-[1240px]  mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-8 px-8 py-6"
                 
                >
                   {
                   status ? (
          searchClaims.length > 0 ? searchClaims.filter((item)=>{
            if(searchBusiness == ""){
              return item
            }else if ((item.name).toLowerCase().includes(searchBusiness.toLowerCase())){
              return item
            }
          }).map((item, key) => {
                  return <><div className="new-claim p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md "key={key}>
                    <img
                      className="mb-3 w-24 h-24 rounded-full shadow-lg mx-16"
                      src={ps}
                      alt="image"
                    />

                    <h4 className="mb-2 text-2xl font-semibold tracking-tight">
                      {item.name}
                    </h4>

                    <p className="mb-3 font-normal ">
                      {item.industry}
                    </p>
                    <p className="mb-3 font-normal ">
                      {`${item.streetaddress}  ${item.postalcode}`}
                    </p>
                    <button
                      className="claim-btn" onClick={handleClickOpen} 
                      >
                      
                      Get Listed
                    </button>
                    {/* <a className="order-news-btn pt-5" href={`https://paysprint.ca/shop/${item.merchant.businessname}`}>
                Get Listed
              </a> */}
                    
            
                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle>Subscribe</DialogTitle>
                      <DialogContent>
                      
                        <p  style={{color: "blue"}}>{message}</p>
                        <p style={{color: "red"}}>{error}</p>
                      
                        <DialogContentText>
                          To claim your business, please enter your email address here. We
                          will send a link.
                        </DialogContentText>
                        <TextField
                          autoFocus
                          onChange={handleFormDataChange}
                          margin="dense"
                          id="email"
                          name="email"
                          label="Email Address"
                          type="email"
                          fullWidth
                          variant="standard"
                        />
                        <Typography mt={2}>To claim business with number,<Link to="/numberclaim"><span className="click-btn">Click here</span></Link></Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button  onClick={handleSubmit}>Submit</Button>
                      </DialogActions>
                    </Dialog>

                   
                  </div>
                  
                   </>
                 
         
                })
              : 
                
                <div className="px-5 pb-5">
                  <h5 className="text-xl font-medium tracking-tight text-gray-900 dark:text-white">
                    No available store at the moment
                  </h5>
                  
                </div>
                ) : <p>Loading...</p>
              }
     
            </div>
          </div>
  );
};

export default ClaimBusiness;

import React from 'react'


import {RequestReview,Navbar} from '../find_a_product'

const Search = () => {
  return (
    <>
   <Navbar/>
   <div className='container'>
    <RequestReview/>
   </div>
   </>
  )
}

export default Search

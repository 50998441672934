import React, { useState, useEffect } from 'react'
import axios from 'axios'
import baseurl from '../constants/baseurl';
import headers from '../constants/headers';
import { Link } from 'react-router-dom';

function Categories () {

  const [categoryItem, setCategoryItem] = useState();
  const [status, setStatus] = useState(false);

  useEffect(() => {
    getAllCategory();
  }, []);


  const getAllCategory = async () => {
    try {

      const config = {
        method: 'GET',
        url: `${baseurl}/getmarketcategory`,
        headers
      }

      const response = await axios(config);
      setCategoryItem(response.data.data);
      setStatus(true);
      
    } catch (error) {
      console.log(error);
    }
  }


  return (

<div className="p-4 w-full  max-w-md bg-slate-100 rounded-lg border shadow-md sm:p-8 ">
    <div className="flex justify-between items-center mb-2 bg-[#e8aa07] py-3 px-5 rounded ">
        <h5 className="text-xl font-bold leading-none text-gray-900">Products/Services</h5>
        
   </div>
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 ">
          
          {
            status ?
            
            (categoryItem.length > 0 ? categoryItem.slice(0,19).map((item, key) => {
              return item.industry !== null && <li className="py-3 sm:py-4 font-bold text-sm" key={key}><a href={`https://paysprint.ca/merchantcategory?service=${item.industry}`}>{item.industry}</a>
              <span className='py-3  px-4 sm:py-4 font-bold text-sm'>({item.total})</span>
              </li>
               
           
              
            })
             : <li className="py-3 sm:py-4 font-bold text-sm"> No category yet</li>) : <li className="py-3 sm:py-4 font-bold text-sm"> Loading...</li>
          }
            
      
          
        </ul>
   </div>
   <button className="flex justify-between items-center font-bold  bg-[#f75641] py-3 px-20 rounded my-3"><Link to='/viewmore'>View More</Link>
       

    </button>
</div>

   
  )
}

export default Categories
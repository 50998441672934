import React from "react";


const Card = () => {
  return (
    <div className="w-full py-[3rem] px-4 bg-slate-100">
        <h4 className="text-3xl font-medium  pb-8 text-black text-center">
          Business Account or  Personal Account?
      </h4>
      <div className="max-w-[1240px]  mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-2 px-8">
      
      <div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
 
        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Business Account</h5>
  
    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400"> Earn with your business account and get extra points when you refer</p>
    <a href="https://paysprint.ca/AdminLogin" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-black bg-[#e8aa07] rounded-lg hover:bg-[#f5d279] focus:ring-4 focus:outline-none  dark:bg-[#e8aa07] dark:hover:bg-[#f5d279] ">Click Here
        
        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </a>
      </div>
        <div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          
                <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Personal Account</h5>
           
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Earn with your personal account and get extra points when you refer</p>
            <a href="https://paysprint.ca/login" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-black bg-[#e8aa07] rounded-lg hover:bg-[#f5d279] focus:ring-4 focus:outline-none  dark:bg-[#e8aa07] dark:hover:bg-[#f5d279] ">Click Here
                
                <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
        </div>

       
      </div>
    </div>
  );
};

export default Card;

import React from 'react'


import {SearchBusiness,Navbar,} from '../find_a_product'
// import ColouredLine from '../find_a_product'
const Search = () => {
  return (
    <>
   <Navbar/>
   <div className='container'>
    <SearchBusiness/>
    {/* <ColouredLine color="red" /> */}
    </div>
   </>
  )
}

export default Search

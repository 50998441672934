import React from "react";
import {
  Navbar,
  Market,
  News,
  Services,
  Specialpromo,
  Popular,
  Categories,
  Video
} from "../components";


function Discover() {
  return (
    <>
    
    <Navbar/>
    <div className="container">
      <div className="main">
        <div className="side1">
          <Market />
          <Specialpromo/>
          <Services />
          
        </div>
        <div className="side2">
          <Categories />
          <Video/>
        </div>
      </div>
      {/* <Popular/> */}
      
    
      <News/>
    </div>
    </>
    
  );
}

export default Discover;
